<template lang="pug">
  .transport-icon
    v-tooltip(top)
      template(v-slot:activator="{ on }")
        div(v-on="on")
          component(:is="icon")
      span.tooltip-text(v-if="transportType !== TRANSPORT_TYPE.ENOENT") {{ transportType }}
      span(v-else) No transport arrangement
</template>

<script>
import { TRANSPORT_TYPE } from '@/app/admin/modules/course_managment/core/cm-const'

export default {
  props: {
    transportType: {
      required: true,
      type: String
    }
  },

  data: () => ({
    TRANSPORT_TYPE
  }),

  computed: {
    icon() {
      switch (this.transportType) {
      case TRANSPORT_TYPE.BUS:
        return 'icoBus'
      case TRANSPORT_TYPE.TAXI:
        return 'icoTaxi'
      case TRANSPORT_TYPE.LOPEND:
        return 'icoLopen'
      case TRANSPORT_TYPE.ENOENT:
        return 'icoEnoent'
      case TRANSPORT_TYPE.ZELF_EXAM:
        return 'icoZelfExam'
      default:
        return ''
      }
    }
  },

  components: {
    icoBus: () => import("@/assets/img/ui/components/IcoBus.vue"),
    icoTaxi: () => import("@/assets/img/ui/components/IcoTaxi.vue"),
    icoLopen: () => import("@/assets/img/ui/components/IcoLopen.vue"),
    icoEnoent: () => import("@/assets/img/ui/components/IcoNoTransport.vue"),
    icoZelfExam: () => import("@/assets/img/ui/components/IcoZelfExam.vue")
  }
}
</script>

<style lang="scss">
.transport-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: $layout-bg;
}

.tooltip-text {
  text-transform: capitalize;
}
</style>
